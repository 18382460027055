import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ProductLandingPage, ProductMainPage, ProductPage } from './index';

import { Navigation, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

const ProductSwiper = (props) => {
  const location = useLocation();
  const products = useSelector((state) => state.products);
  const [currentProduct, setCurrentProduct] = useState({});
  const [horizontalFirstSwiper, setHorizontalFirstSwiper] = useState();
  const [horizontalSecondSwiper, setHorizontalSecondSwiper] = useState();
  const [verticalSwiper, setVerticalSwiper] = useState();

  useEffect(() => {
    products.items.forEach((item) => {
      if (item?.id === location.pathname.split('/').pop()) {
        setCurrentProduct(item);
      }
    });
    horizontalFirstSwiper?.slideTo(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, products]);

  return (
    <>
      {products.loaded === true ? (
        <Swiper
          centeredSlides={true}
          grabCursor={true}
          className="mySwiper swiper-h product-page horizontalFirstSwiper"
          autoHeight={true}
          id="horizontalFirstSwiper"
          onSwiper={setHorizontalFirstSwiper}
          spaceBetween={1}
          allowTouchMove={false}
          speed={1500}
        >
          <SwiperSlide>
            <ProductLandingPage props={verticalSwiper} />
          </SwiperSlide>
          <SwiperSlide>
            <Swiper
              centeredSlides={true}
              grabCursor={true}
              className="mySwiper2 swiper-v verticalSwiper"
              autoHeight={true}
              direction={'vertical'}
              id="verticalSwiper"
              onSwiper={setVerticalSwiper}
              spaceBetween={1}
              speed={0}
            >
              <SwiperSlide>
                <ProductMainPage props={horizontalSecondSwiper} />
              </SwiperSlide>
              {currentProduct?.advantage_elements?.map(
                (advantage_element, i) => (
                  <SwiperSlide key={i}>
                    <Swiper
                      navigation={true}
                      modules={[Navigation, Controller]}
                      className="mySwiper horizontalSecondSwiper"
                      centeredSlides={true}
                      autoHeight={true}
                      id="horizontalSecondSwiper"
                      spaceBetween={1}
                      onSwiper={setHorizontalSecondSwiper}
                      speed={1500}
                    >
                      {advantage_element.slides?.map((slide, i) => (
                        <SwiperSlide key={i}>
                          <ProductPage
                            verticalSwiper={verticalSwiper}
                            data={slide}
                            advantage_element={advantage_element}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </SwiperSlide>
                ),
              )}
            </Swiper>
          </SwiperSlide>
        </Swiper>
      ) : (
        <div className="center-fit spinner">
          <div className="loadingspinner">
            <div id="square1"></div>
            <div id="square2"></div>
            <div id="square3"></div>
            <div id="square4"></div>
            <div id="square5"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductSwiper;
