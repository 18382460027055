import { DEFAULT_LANGUAGE_DICT, DB_KEYS } from '../config';
import { getStorage, setStorage } from './storage';

export const changeLocalStorageLanguage = async (language_dict) => {
  await setStorage(DB_KEYS.LANGUAGE, language_dict);
  return language_dict;
};

export const getLocalStorageLanguage = async () => {
  const language =
    (await getStorage(DB_KEYS.LANGUAGE)) || DEFAULT_LANGUAGE_DICT;
  return language;
};
