/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import { Col, Image, Row } from 'react-bootstrap';

import './ProductImages.css';

import PhotoSwipe from 'photoswipe';
import 'photoswipe/dist/photoswipe.css';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

const ProductImage = ({ data }) => {
  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#my-gallery',
      children: 'a',
      showHideAnimationType: 'fade',
      showAnimationDuration: 1000,
      hideAnimationDuration: 1000,
      pswpModule: () => PhotoSwipe,
    });

    lightbox.on('contentLoad', (e) => {
      const { content } = e;
      if (content.type === 'youtube-video') {
        e.preventDefault();
        content.element = document.createElement('div');
        content.element.className = 'pswp__youtube-video-container';
        const iframe = document.createElement('iframe');
        iframe.setAttribute('allowfullscreen', '');
        iframe.src = content.data.src;
        content.element.appendChild(iframe);
      }
    });

    lightbox.init();
  }, []);

  return (
    <div>
      {data?.slide_type === '1' &&
        (data?.multimedia?.[0]?.media_type === 'Video' ? (
          <div className="slide_type_1">
            <Zoom style={{ height: '100%' }} delay={'50'}>
              <div className="pswp-gallery" id="my-gallery">
                <a
                  data-pswp-type="youtube-video"
                  data-youtube-video-url={data?.multimedia?.[0]?.video_url
                    .replace('watch?v=', 'embed/')
                    .concat(`?vq=hd720&rel=0&loop=1`)}
                  href={data?.multimedia?.[0]?.video_url
                    .replace('watch?v=', 'embed/')
                    .concat(`?vq=hd720&rel=0&loop=1`)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    width={'100%'}
                    className="slide_type_1_img_1"
                    src={data?.multimedia?.[0]?.image_url}
                  />
                </a>
              </div>
            </Zoom>
          </div>
        ) : (
          <div className="slide_type_1">
            <Zoom style={{ height: '100%' }} delay={'50'}>
              <Image
                className="slide_type_1_img_1"
                src={data?.multimedia?.[0]?.image_url}
              />
            </Zoom>
          </div>
        ))}
      {data?.slide_type === '2' && (
        <div className="slide_type_2">
          <Row>
            <Col className="border-end border-bottom border-white border-2">
              <Zoom style={{ height: '100%' }} delay={'50'}>
                {data?.multimedia?.[0]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        width={'100%'}
                        className="slide_type_2_img_1"
                        src={data?.multimedia?.[0]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_2_img_1"
                    src={data?.multimedia?.[0]?.image_url}
                  />
                )}
              </Zoom>
            </Col>

            <Col className="border-start border-bottom border-white border-2">
              <Fade style={{ height: '100%' }} delay={'100'}>
                {data?.multimedia?.[1]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_2_img_2"
                        width={'100%'}
                        src={data?.multimedia?.[1]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_2_img_2"
                    src={data?.multimedia?.[1]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col className="border-end border-top border-white border-2">
              <Fade style={{ height: '100%' }} delay={'150'}>
                {data?.multimedia?.[2]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[2]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[2]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_2_img_3"
                        width={'100%'}
                        src={data?.multimedia?.[2]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_2_img_3"
                    src={data?.multimedia?.[2]?.image_url}
                  />
                )}
              </Fade>
            </Col>
            <Col className="border-start border-top border-white border-2">
              <Fade style={{ height: '100%' }} delay={'200'}>
                {data?.multimedia?.[3]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[3]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[3]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_2_img_4"
                        width={'100%'}
                        src={data?.multimedia?.[3]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_2_img_4"
                    src={data?.multimedia?.[3]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
        </div>
      )}
      {data?.slide_type === '3' && (
        <div className="slide_type_3">
          <Row className="border-bottom border-white border-2">
            <Col>
              <Fade style={{ height: '100%' }} direction={'right'}>
                {data?.multimedia?.[0]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_3_img_1"
                        width={'100%'}
                        src={data?.multimedia?.[0]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_3_img_1"
                    src={data?.multimedia?.[0]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
          <Row className="border-top border-white border-2">
            <Col>
              <Fade style={{ height: '100%' }} direction={'right'} delay={'50'}>
                {data?.multimedia?.[1]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_3_img_2"
                        width={'100%'}
                        src={data?.multimedia?.[1]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_3_img_2"
                    src={data?.multimedia?.[1]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
        </div>
      )}
      {data?.slide_type === '4' && (
        <div className="slide_type_4">
          <Row className="border-bottom border-white border-2">
            <Col>
              <Fade style={{ height: '100%' }} direction={'right'}>
                {data?.multimedia?.[0]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_4_img_1"
                        width={'100%'}
                        src={data?.multimedia?.[0]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_4_img_1"
                    src={data?.multimedia?.[0]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col className="border-top border-end border-white border-2">
              <Fade
                style={{ height: '100%' }}
                direction={'right'}
                delay={'100'}
              >
                {data?.multimedia?.[1]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_4_img_2"
                        width={'100%'}
                        src={data?.multimedia?.[1]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_4_img_2"
                    src={data?.multimedia?.[1]?.image_url}
                  />
                )}
              </Fade>
            </Col>
            <Col className="border-top border-start border-white border-2">
              <Fade
                style={{ height: '100%' }}
                direction={'right'}
                delay={'150'}
              >
                {data?.multimedia?.[2]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[2]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[2]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_4_img_3"
                        width={'100%'}
                        src={data?.multimedia?.[2]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_4_img_3"
                    src={data?.multimedia?.[2]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
        </div>
      )}
      {data?.slide_type === '5' && (
        <div className="slide_type_5">
          <Row>
            <Col className="border-bottom border-end border-white border-2">
              <Fade style={{ height: '100%' }} direction={'down'} delay={'50'}>
                {data?.multimedia?.[0]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_5_img_1"
                        width={'100%'}
                        src={data?.multimedia?.[0]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_5_img_1"
                    src={data?.multimedia?.[0]?.image_url}
                  />
                )}
              </Fade>
            </Col>
            <Col className="border-bottom border-start border-white border-2">
              <Fade style={{ height: '100%' }} direction={'down'} delay={'100'}>
                {data?.multimedia?.[1]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_5_img_2"
                        width={'100%'}
                        src={data?.multimedia?.[1]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_5_img_2"
                    src={data?.multimedia?.[1]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
          <Row className="border-top border-white border-2">
            <Col>
              <Fade style={{ height: '100%' }} direction={'up'} delay={'150'}>
                {data?.multimedia?.[2]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[2]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[2]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_5_img_3"
                        width={'100%'}
                        src={data?.multimedia?.[2]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_5_img_3"
                    src={data?.multimedia?.[2]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
        </div>
      )}
      {data?.slide_type === '6' && (
        <div className="slide_type_6">
          <Row>
            <Col className="border-end border-white border-2">
              <Fade style={{ height: '100%' }} direction={'left'} delay={'50'}>
                {data?.multimedia?.[0]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_6_img_1"
                        width={'100%'}
                        src={data?.multimedia?.[0]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_6_img_1"
                    src={data?.multimedia?.[0]?.image_url}
                  />
                )}
              </Fade>
            </Col>
            <Col className="border-start border-white border-2">
              <Fade
                style={{ height: '100%' }}
                direction={'right'}
                delay={'100'}
              >
                {data?.multimedia?.[1]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[1]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_6_img_2"
                        width={'100%'}
                        src={data?.multimedia?.[1]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_6_img_2"
                    src={data?.multimedia?.[1]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
        </div>
      )}
      {data?.slide_type === '7' && (
        <div className="slide_type_7">
          <Row>
            <Col className="border-end border-white border-4">
              <Fade style={{ height: '100%' }} direction={'left'} delay={'50'}>
                {data?.multimedia?.[0]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[0]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_7_img_1"
                        width={'100%'}
                        src={data?.multimedia?.[0]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_7_img_1"
                    src={data?.multimedia?.[0]?.image_url}
                  />
                )}
              </Fade>
            </Col>
            <Col className="">
              <Row>
                <Col className="border-bottom border-white border-2">
                  <Fade
                    style={{ height: '100%' }}
                    direction={'right'}
                    delay={'100'}
                  >
                    {data?.multimedia?.[1]?.media_type === 'Video' ? (
                      <div className="pswp-gallery" id="my-gallery">
                        <a
                          data-pswp-type="youtube-video"
                          data-youtube-video-url={data?.multimedia?.[1]?.video_url
                            .replace('watch?v=', 'embed/')
                            .concat(`?vq=hd720&rel=0&loop=1`)}
                          href={data?.multimedia?.[1]?.video_url
                            .replace('watch?v=', 'embed/')
                            .concat(`?vq=hd720&rel=0&loop=1`)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            className="slide_type_7_img_2"
                            width={'100%'}
                            src={data?.multimedia?.[1]?.image_url}
                          />
                        </a>
                      </div>
                    ) : (
                      <Image
                        className="slide_type_7_img_2"
                        src={data?.multimedia?.[1]?.image_url}
                      />
                    )}
                  </Fade>
                </Col>
              </Row>
              <Row>
                <Col className="border-top border-white border-2">
                  <Fade
                    style={{ height: '100%' }}
                    direction={'right'}
                    delay={'150'}
                  >
                    {data?.multimedia?.[2]?.media_type === 'Video' ? (
                      <div className="pswp-gallery" id="my-gallery">
                        <a
                          data-pswp-type="youtube-video"
                          data-youtube-video-url={data?.multimedia?.[2]?.video_url
                            .replace('watch?v=', 'embed/')
                            .concat(`?vq=hd720&rel=0&loop=1`)}
                          href={data?.multimedia?.[2]?.video_url
                            .replace('watch?v=', 'embed/')
                            .concat(`?vq=hd720&rel=0&loop=1`)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            className="slide_type_7_img_3"
                            width={'100%'}
                            src={data?.multimedia?.[2]?.image_url}
                          />
                        </a>
                      </div>
                    ) : (
                      <Image
                        className="slide_type_7_img_3"
                        src={data?.multimedia?.[2]?.image_url}
                      />
                    )}
                  </Fade>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
      {data?.slide_type === '8' && (
        <div className="slide_type_8">
          <Row>
            <Col>
              <Row className="border-bottom border-end border-white border-2">
                <Col>
                  <Fade
                    style={{ height: '100%' }}
                    direction={'down'}
                    delay={'50'}
                  >
                    {data?.multimedia?.[0]?.media_type === 'Video' ? (
                      <div className="pswp-gallery" id="my-gallery">
                        <a
                          data-pswp-type="youtube-video"
                          data-youtube-video-url={data?.multimedia?.[0]?.video_url
                            .replace('watch?v=', 'embed/')
                            .concat(`?vq=hd720&rel=0&loop=1`)}
                          href={data?.multimedia?.[0]?.video_url
                            .replace('watch?v=', 'embed/')
                            .concat(`?vq=hd720&rel=0&loop=1`)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            className="slide_type_8_img_1"
                            width={'100%'}
                            src={data?.multimedia?.[0]?.image_url}
                          />
                        </a>
                      </div>
                    ) : (
                      <Image
                        className="slide_type_8_img_1"
                        src={data?.multimedia?.[0]?.image_url}
                      />
                    )}
                  </Fade>
                </Col>
              </Row>
              <Row className="border-top border-end border-white border-2">
                <Col>
                  <Fade
                    style={{ height: '100%' }}
                    direction={'up'}
                    delay={'100'}
                  >
                    {data?.multimedia?.[1]?.media_type === 'Video' ? (
                      <div className="pswp-gallery" id="my-gallery">
                        <a
                          data-pswp-type="youtube-video"
                          data-youtube-video-url={data?.multimedia?.[1]?.video_url
                            .replace('watch?v=', 'embed/')
                            .concat(`?vq=hd720&rel=0&loop=1`)}
                          href={data?.multimedia?.[1]?.video_url
                            .replace('watch?v=', 'embed/')
                            .concat(`?vq=hd720&rel=0&loop=1`)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            className="slide_type_8_img_2"
                            width={'100%'}
                            src={data?.multimedia?.[1]?.image_url}
                          />
                        </a>
                      </div>
                    ) : (
                      <Image
                        className="slide_type_8_img_2"
                        src={data?.multimedia?.[1]?.image_url}
                      />
                    )}
                  </Fade>
                </Col>
              </Row>
            </Col>
            <Col className="border-start border-white border-2">
              <Fade style={{ height: '100%' }} direction={'down'} delay={'150'}>
                {data?.multimedia?.[2]?.media_type === 'Video' ? (
                  <div className="pswp-gallery" id="my-gallery">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={data?.multimedia?.[2]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={data?.multimedia?.[2]?.video_url
                        .replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="slide_type_8_img_3"
                        width={'100%'}
                        src={data?.multimedia?.[2]?.image_url}
                      />
                    </a>
                  </div>
                ) : (
                  <Image
                    className="slide_type_8_img_3"
                    src={data?.multimedia?.[2]?.image_url}
                  />
                )}
              </Fade>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ProductImage;
