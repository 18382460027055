import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { usePushRoute } from '../hooks';
import { useSelector } from 'react-redux';
import { ROUTES } from '../config';

import { getDisplayedLanguages } from '../api/displayedlanguages';
import { useAsync } from '../hooks';
import { setLanguage } from '../store/actions/language';

const Admin = () => {
  const pushRoute = usePushRoute();

  const dispatch = useDispatch();
  const { triggerFunction, data, loading, loaded } = useAsync();
  useEffect(() => {
    triggerFunction(getDisplayedLanguages);
  }, [triggerFunction]);
  const products = useSelector((state) => state.products);
  const language_state = useSelector((state) => state.language);
  const [isValid, setIsValid] = useState(null);
  const formSubmitHandler = (e) => {
    // e.preventDefault();
    if (e.target.value !== '') {
      dispatch(
        setLanguage({
          code: e.target.value.split('|')[0],
          title: e.target.value.split('|')[1],
        }),
      );
      setIsValid(true);
      setTimeout(() => {
        setIsValid(null);
      }, 3000);
    } else {
      setIsValid(false);
    }
  };
  return (
    <div className="admin-panel-container">
      <div className="admin-panel">
        <h1>
          <FormattedMessage id="admin" defaultMessage="Settings panel" />
        </h1>

        {loading && <p>Loading...</p>}
        {loaded && (
          <>
            {isValid === true && (
              <Alert variant="success">
                Default language changed to {language_state.language_dict.title}
              </Alert>
            )}
            <Container>
              <Row>
                <Col>
                  <h2>
                    <FormattedMessage
                      id="select-application-language"
                      defaultMessage="Application language"
                    />
                    :
                  </h2>
                </Col>
                <Col>
                  {isValid === false && (
                    <Alert variant="danger">Oops! Try again</Alert>
                  )}
                  <Form>
                    <Form.Select
                      onChange={formSubmitHandler}
                      name="language"
                      aria-label="Default select example"
                      value={`${language_state.language_dict.code}|${language_state.language_dict.title}`}
                    >
                      {data.items.map((apilanguage, i) => (
                        <option
                          selected={
                            language_state.language_dict.code ===
                            apilanguage['id']
                          }
                          key={i}
                          value={`${apilanguage['id']}|${apilanguage['title']}`}
                        >
                          {apilanguage.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form>
                </Col>
              </Row>
            </Container>
            <Button
              variant="primary"
              onClick={() => {
                pushRoute(ROUTES.PRODUCT, {
                  id: products.items[0].id,
                });
                window.location.reload(false);
              }}
            >
              <FormattedMessage
                id="return-home-page"
                defaultMessage="Return to home page"
              />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Admin;
