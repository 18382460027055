import { Home, Admin, ProductSwiper, VideoGallery } from '../pages';

export const INDEX_COMPONENT = Home;
const ROUTES = {
  ADMIN: {
    path: 'admin',
    Component: Admin,
  },
  PRODUCT: {
    path: '/products/:id',
    Component: ProductSwiper,
  },
  VIDEO_GALLERY: {
    path: '/products/:id/video_gallery',
    Component: VideoGallery,
  },
};

export default ROUTES;
