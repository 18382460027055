import React, { useEffect, useState } from 'react';
import { Row, Container, Col, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StringToHTML } from '../../hooks';
import { Zoom } from 'react-awesome-reveal';

const ProductTitle = ({ verticalSwiper, data, advantage_element }) => {
  const products = useSelector((state) => state.products);
  const [currentProduct, setCurrentProduct] = useState({});
  const location = useLocation();
  useEffect(() => {
    products.items.forEach((item) => {
      if (item?.id === location.pathname.split('/').pop()) {
        setCurrentProduct(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Container>
      <Row
        role={'button'}
        tabIndex={0}
        className="product-title"
        onClick={() => verticalSwiper?.slideTo(0)}
        onKeyDown={() => verticalSwiper?.slideTo(0)}
      >
        <Zoom>
          <h1 className="product-title">{currentProduct?.product_name}</h1>
        </Zoom>
      </Row>
      <Row>
        <Col md={2}>
          <Zoom>
            <div className="slide-image-container">
              <Image
                className="slide-image"
                src={advantage_element?.image_url}
              />
            </div>
          </Zoom>
        </Col>
        <Col>
          <Row>
            <Zoom>
              <div className="product-title-container">
                <h2>{advantage_element?.title}</h2>
                <h3>{advantage_element?.description}</h3>
              </div>
            </Zoom>
          </Row>
          <Row>
            <Zoom>
              <div className="product-data-container">
                <StringToHTML string={data?.text} />
              </div>
            </Zoom>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductTitle;
