import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../../config';
import { usePushRoute } from '../../hooks';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TopIcons = () => {
  const products = useSelector((state) => state.products);
  const pushRoute = usePushRoute();
  const location = useLocation();

  const [currentProduct, setCurrentProduct] = useState({});
  useEffect(() => {
    products.items.forEach((item) => {
      if (location.pathname.includes(item.id)) {
        setCurrentProduct(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <Row className="position-absolute top-0 end-0 d-flex icons">
      <Col
        xs
        lg="1"
        className="rounded-icon-bg tablet-icon"
        onClick={() => {
          window.location.replace('https://tablet.ulmaconstruction.com');
        }}
      >
        <svg
          width="24"
          height="32"
          viewBox="0 0 24 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.83341 0.138939C1.57356 0.486074 0.369332 1.76121 0.109752 3.02338C0.0150755 3.48329 -0.0161091 7.56736 0.00759116 16.3954L0.0416446 29.1051L0.312327 29.6962C0.658226 30.4516 1.49734 31.2983 2.29018 31.6919L2.91063 32H11.8918H20.873L21.4934 31.6919C22.2862 31.2983 23.1254 30.4516 23.4713 29.6962L23.7419 29.1051V16.0152V2.92521L23.4713 2.33415C23.1254 1.57871 22.2862 0.732014 21.4934 0.338434L20.873 0.0303172L12.1413 0.00527011C5.43857 -0.0139872 3.27574 0.0171014 2.83341 0.138939ZM20.8248 2.25611C21.1337 2.43132 21.4 2.71967 21.559 3.05107C21.8034 3.56032 21.8085 3.82816 21.8085 16.0152C21.8085 28.4097 21.8075 28.4614 21.5459 29.0067C21.3659 29.3816 21.1318 29.632 20.8024 29.8015C20.3383 30.0404 20.0276 30.0491 11.8918 30.0491C3.62812 30.0491 3.45212 30.0439 2.95865 29.7899C2.60227 29.6066 2.38598 29.3768 2.21795 29.0033C1.98981 28.4963 1.98045 27.992 1.97783 16.0235C1.97509 3.62162 1.97621 3.56888 2.23741 3.02427C2.40893 2.66668 2.65741 2.39343 2.95466 2.23484C3.39062 2.00249 3.76334 1.99217 11.8748 1.98676L20.3401 1.98122L20.8248 2.25611Z"
            fill="#CCCCCC"
          />
        </svg>
      </Col>
      <Col
        xs
        lg="1"
        className="rounded-icon-bg video-icon ms-3"
        onClick={() => {
          pushRoute(ROUTES.VIDEO_GALLERY, {
            id: currentProduct.id,
          });
        }}
      >
        <svg
          width="32"
          height="20"
          viewBox="0 0 32 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.15883 0.237406C2.13472 0.596343 1.34552 1.17942 0.833674 1.95518C-0.00478478 3.22597 -0.0325483 3.49571 0.0134395 9.93026L0.0550136 15.7693L0.428041 16.5678C0.633207 17.0071 1.04411 17.6104 1.34111 17.9088C1.63825 18.207 2.23922 18.6195 2.67674 18.8255L3.47206 19.2H10.6621H17.8521L18.6475 18.8255C19.6585 18.3495 20.4221 17.5829 20.8962 16.5678L21.2692 15.7693V9.62264V3.47598L20.8962 2.67749C20.4221 1.66243 19.6585 0.895813 18.6475 0.419804L17.8521 0.0452871L10.8757 0.0115519C4.45048 -0.0194673 3.84068 -0.00159885 3.15883 0.237406ZM29.3135 1.10437C28.6939 1.30321 23.1363 5.33856 22.7997 5.83416C22.4902 6.28972 22.4794 6.4178 22.4794 9.62264C22.4794 12.8273 22.4902 12.9556 22.7997 13.4114C23.1795 13.9708 28.8916 18.0646 29.4483 18.1764C30.4498 18.3775 31.6036 17.6812 31.8872 16.7045C31.9821 16.3777 32.0188 13.7555 31.9909 9.31131C31.9486 2.61388 31.9394 2.41375 31.6539 2.02994C31.0481 1.21558 30.1158 0.846783 29.3135 1.10437ZM17.9945 2.44263C18.3698 2.65333 18.6732 2.97982 18.8844 3.39965C19.2003 4.02804 19.2047 4.1138 19.2047 9.6305C19.2047 15.1937 19.2029 15.2274 18.8768 15.8465C18.6527 16.2719 18.3527 16.5731 17.929 16.7981L17.309 17.1273H10.6699C4.06777 17.1273 4.02719 17.1254 3.39603 16.8057C2.97787 16.5937 2.65268 16.2891 2.44282 15.9122C2.12774 15.3468 2.12418 15.2773 2.12418 9.62264C2.12418 3.95371 2.12688 3.89996 2.4451 3.33589C2.62506 3.01698 3.01489 2.6256 3.33253 2.44491C3.89848 2.12314 3.90774 2.12272 10.6621 2.12272C17.3998 2.12272 17.4271 2.12386 17.9945 2.44263ZM29.883 9.63108C29.883 14.6865 29.8463 15.9793 29.705 15.8972C29.6072 15.8405 28.3898 14.9944 26.9999 14.0172L24.4727 12.2404V9.62079V7.00116L27.0711 5.13987C28.5001 4.11624 29.7176 3.27471 29.7762 3.27014C29.8349 3.26542 29.883 6.12791 29.883 9.63108Z"
            fill="#CCCCCC"
          />
        </svg>
      </Col>
    </Row>
  );
};

export default TopIcons;
