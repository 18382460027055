import {
  changeLocalStorageLanguage,
  getLocalStorageLanguage,
} from '../../helpers/language';

// export const SET_LANGUAGE = 'SET_LANGUAGE';
// export const setLanguage = (language_dict) => {
//   return {
//     type: SET_LANGUAGE,
//     payload: {
//       language_dict,
//     },
//   };
// };
export const SET_LANGUAGE_PENDING = 'SET_LANGUAGE_PENDING';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';
export const SET_LANGUAGE_ERROR = 'SET_LANGUAGE_ERROR';
export const SET_INIT_LANGUAGE_PENDING = 'SET_INIT_LANGUAGE_PENDING';
export const SET_INIT_LANGUAGE_SUCCESS = 'SET_INIT_LANGUAGE_SUCCESS';
export const SET_INIT_LANGUAGE_ERROR = 'SET_INIT_LANGUAGE_ERROR';
export const setLanguage = (language_dict) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LANGUAGE_PENDING,
    });
    const localstorage_language_dict = await changeLocalStorageLanguage(
      language_dict,
    );

    try {
      dispatch({
        type: SET_LANGUAGE_SUCCESS,
        payload: {
          localstorage_language_dict,
        },
      });
    } catch (error) {
      dispatch({
        type: SET_LANGUAGE_ERROR,
        payload: error,
      });
      // console.error(error);
    }
  };
};

export const setInitLanguage = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_INIT_LANGUAGE_PENDING,
    });
    const localstorage_language_dict = await getLocalStorageLanguage();
    try {
      dispatch({
        type: SET_INIT_LANGUAGE_SUCCESS,
        payload: {
          localstorage_language_dict,
        },
      });
    } catch (error) {
      dispatch({
        type: SET_INIT_LANGUAGE_ERROR,
        payload: error,
      });
      // console.error(error);
    }
  };
};
