import React from 'react';
import { Container } from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { BurgerMenu } from './components';
import { DEFAULT_LANGUAGE_DICT } from './config';
import './formatjs-polyfills';
import initialLoader from './helpers/hocs/initialLoader';
import messages_en from './locales/en/en.json';
import messages_es from './locales/es/es.json';
import messages_eu from './locales/eu/eu.json';
import './theme/global.scss';

// Eslint disables the following line
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Routes from './Routes';

const messages = {
  eu: messages_eu,
  es: messages_es,
  en: messages_en,
};

function App() {
  const { language } = useSelector((state) => state.language);

  return (
    <IntlProvider
      locale={
        language ? language.language_dict.code : DEFAULT_LANGUAGE_DICT.code
      }
      messages={
        messages[
          language ? language.language_dict.code : DEFAULT_LANGUAGE_DICT.code
        ]
      }
    >
      <div id="outer-container">
        <BurgerMenu />
        <Container id="page-wrap">
          <Outlet />
        </Container>
      </div>
    </IntlProvider>
  );
}

export default initialLoader(App);
