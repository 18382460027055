import React, { useEffect, useState } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import { Image, Col, Row } from 'react-bootstrap';
import { IdleTimerProvider } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ImagesCarousel } from '../../components';
import { TIMEOUT } from '../../config';
import { StringToHTML } from '../../hooks';

import { useSwiper } from 'swiper/react';

const ProductMainPage = (props) => {
  const [isIdle, setIsIdle] = useState();

  const horizontalSecondSwiper = props;
  const products = useSelector((state) => state.products);
  const [currentProduct, setCurrentProduct] = useState({});
  const location = useLocation();
  const swiper = useSwiper();
  useEffect(() => {
    products.items.forEach((item) => {
      if (item?.id === location.pathname.split('/').pop()) {
        setCurrentProduct(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = (event) => {
    setIsIdle(false);
    window.location.reload(false);
  };

  return (
    <IdleTimerProvider timeout={TIMEOUT} onIdle={onIdle} onActive={onActive}>
      {isIdle === true ? (
        <Zoom duration={'1500'}>
          <ImagesCarousel />
        </Zoom>
      ) : (
        <div className="product-main-page">
          <div
            className="main-page-title"
            role={'button'}
            tabIndex={0}
            onClick={() => swiper.slidePrev()}
            onKeyDown={() => swiper.slidePrev()}
          >
            <Fade delay={'100'} direction={'down'} duration={'2500'}>
              <h1>{currentProduct?.product_name}</h1>
            </Fade>
          </div>
          <Fade delay={'100'} direction={'down'} duration={'2500'}>
            <p className="product-description">
              <StringToHTML
                string={currentProduct?.showroom_product_advantage_text?.data}
              />
            </p>
          </Fade>
          <Row className="product-advantages">
            {currentProduct?.advantage_elements?.map((advantage_element, i) => (
              <Col key={i}>
                <Fade
                  direction={'up'}
                  duration={'2000'}
                  delay={(i * 30).toString()}
                >
                  <div
                    tabIndex={0}
                    className="box"
                    role={'button'}
                    onKeyDown={() =>
                      swiper
                        ?.slideTo(i + 1)
                        .then(horizontalSecondSwiper?.props.slideTo(0))
                    }
                    onClick={() =>
                      swiper
                        ?.slideTo(i + 1)
                        .then(horizontalSecondSwiper?.props.slideTo(0))
                    }
                  >
                    <Image
                      className="advantage-image"
                      src={advantage_element?.image_url}
                    />
                    <h2>{advantage_element.title}</h2>
                  </div>
                </Fade>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </IdleTimerProvider>
  );
};

export default ProductMainPage;
