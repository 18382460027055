import { combineReducers } from 'redux';

import languageReducer from './language';
import productsReducer from './products';
import imagesReducer from './sliderImages';

const rootReducer = combineReducers({
  language: languageReducer,
  products: productsReducer,
  inactivityImages: imagesReducer,
});

export default rootReducer;
