import React, { useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { useSelector } from 'react-redux';

import { ROUTES } from '../config';
import { usePushRoute } from '../hooks';

const Home = () => {
  const products = useSelector((state) => state.products);
  const pushRoute = usePushRoute();
  useEffect(() => {
    if (products && products.items.length === 1) {
      pushRoute(ROUTES.PRODUCT, { id: products?.items?.[0]?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, window.onorientationchange, window.location]);

  return (
    <>
      {products.loaded === true ? (
        <div className="center-fit">
          <Image className="home-image" src="/showroomimage.jpg" />
        </div>
      ) : (
        <div className="center-fit spinner">
          <div className="loadingspinner">
            <div id="square1"></div>
            <div id="square2"></div>
            <div id="square3"></div>
            <div id="square4"></div>
            <div id="square5"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
