import { getProductsApi } from '../../api/products';
export const GET_PRODUCTS_PENDING = 'GET_PRODUCTS_PENDING';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const getProducts = (langCode) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PRODUCTS_PENDING,
    });
    const result = await getProductsApi(langCode);
    try {
      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: {
          items: result.data.items,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCTS_ERROR,
        payload: error,
      });
      // console.error(error);
    }
  };
};
