import { getImagesApi } from '../../api/sliderImages';
export const GET_IMAGES_PENDING = 'GET_IMAGES_PENDING';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_ERROR = 'GET_IMAGES_ERROR';
export const getImages = (langCode) => {
  return async (dispatch) => {
    dispatch({
      type: GET_IMAGES_PENDING,
    });
    const result = await getImagesApi(langCode);
    try {
      dispatch({
        type: GET_IMAGES_SUCCESS,
        payload: {
          items: result.data.items,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_IMAGES_ERROR,
        payload: error,
      });
      // console.error(error);
    }
  };
};
