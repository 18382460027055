import React, { useEffect, useState } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import Image from 'react-bootstrap/Image';
import { IdleTimerProvider } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ImagesCarousel } from '../../components';
import { TIMEOUT } from '../../config';

import { useSwiper } from 'swiper/react';

const ProductLandingPage = (props) => {
  const products = useSelector((state) => state.products);
  const [currentProduct, setCurrentProduct] = useState({});
  const location = useLocation();
  const swiper = useSwiper();
  const [isIdle, setIsIdle] = useState();

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = (event) => {
    setIsIdle(false);
    window.location.reload(false);
  };

  useEffect(() => {
    products.items.forEach((item) => {
      if (item?.id === location.pathname.split('/').pop()) {
        setCurrentProduct(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <IdleTimerProvider timeout={TIMEOUT} onIdle={onIdle} onActive={onActive}>
      {isIdle === true ? (
        <Zoom duration={'1500'}>
          <ImagesCarousel />
        </Zoom>
      ) : (
        <div
          role={'button'}
          className="product-landing-page"
          tabIndex={0}
          onClick={() => swiper?.slideNext()}
          onKeyDown={() => swiper?.slideNext()}
        >
          <Fade direction={'left'} duration={'2000'}>
            <h1 className="product-title">
              {currentProduct?.showroom_product_title}
            </h1>
          </Fade>
          <Fade direction={'down'} delay={'140'} duration={'2000'}>
            <h2>{currentProduct?.showroom_product_subtitle}</h2>
          </Fade>
          <Fade direction={'down'} delay={'200'} duration={'2000'}>
            <h3>{currentProduct?.showroom_product_description}</h3>
          </Fade>
          <Zoom delay={'250'} duration={'2000'}>
            <Image
              className="product-image"
              src={currentProduct?.image?.scales?.large?.download}
            />
          </Zoom>
        </div>
      )}
    </IdleTimerProvider>
  );
};

export default ProductLandingPage;
