import {
  GET_IMAGES_PENDING,
  GET_IMAGES_SUCCESS,
  GET_IMAGES_ERROR,
} from '../actions/sliderImages';

const initialState = {
  items: [],
  loading: false,
  loaded: false,
  error: null,
};

const imagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IMAGES_PENDING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GET_IMAGES_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        loading: false,
        loaded: true,
      };
    case GET_IMAGES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default imagesReducer;
