import { useNavigate, useLocation } from 'react-router-dom';

const usePushRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pushRoute = (ROUTES, params) => {
    let path = ROUTES.path.replace('/**', location.pathname);

    if (params) {
      const keys = Object.keys(params);
      keys.forEach((key) => {
        path = ROUTES.path.replace(`:${key}`, params[key]);
      });
    }
    navigate(path, params);
  };
  return pushRoute;
};

export default usePushRoute;
