import { useEffect, useState } from 'react';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { scaleRotate as Menu } from 'react-burger-menu';
import { IdleTimerProvider } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES, TIMEOUT } from '../../config';
import { usePushRoute } from '../../hooks';
import { getProducts } from '../../store/actions/products';
import { getImages } from '../../store/actions/sliderImages';
import './burgermenu.css';

const BurgerMenu = (props) => {
  const language = useSelector((state) => state.language);
  const products = useSelector((state) => state.products);
  const code = language.language_dict.code;
  const pushRoute = usePushRoute();
  const [isIdle, setIsIdle] = useState();
  const dispatch = useDispatch();
  const [isMenuOpen, handleMenu] = useState(false);

  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = (event) => {
    setIsIdle(false);
    window.location.reload(false);
  };

  useEffect(() => {
    if (language.loaded) {
      dispatch(getProducts(code));
      dispatch(getImages(code));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, code, language.loaded]);
  return (
    <>
      <IdleTimerProvider
        timeout={TIMEOUT}
        onIdle={onIdle}
        onActive={onActive}
      />
      {
        !products.loading && products.loaded && (
          /* products.items?.length > 1 ? */
          <Menu
            width={'20%'}
            customBurgerIcon={<img alt="Menu icon" src="/ulma-logo.svg" />}
            customCrossIcon={<img alt="Menu icon" src="/close-search.png" />}
            pageWrapId={'page-wrap'}
            outerContainerId={'outer-container'}
            isOpen={isMenuOpen}
            onStateChange={handleStateChange}
          >
            {isIdle === true ? (
              <Image
                role={'button'}
                className="logo-inside-menu"
                src="/ulma-logo-white.svg"
              />
            ) : (
              <Image
                role={'button'}
                className="logo-inside-menu"
                src="/ulma-logo.svg"
              />
            )}
            <svg
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              width="32px"
              height="32px"
              className="setting-icon"
              role={'button'}
              onClick={() => {
                pushRoute(ROUTES.ADMIN);
                handleCloseMenu();
              }}
            >
              <title>ionicons-v5-q</title>
              <path d="M262.29,192.31a64,64,0,1,0,57.4,57.4A64.13,64.13,0,0,0,262.29,192.31ZM416.39,256a154.34,154.34,0,0,1-1.53,20.79l45.21,35.46A10.81,10.81,0,0,1,462.52,326l-42.77,74a10.81,10.81,0,0,1-13.14,4.59l-44.9-18.08a16.11,16.11,0,0,0-15.17,1.75A164.48,164.48,0,0,1,325,400.8a15.94,15.94,0,0,0-8.82,12.14l-6.73,47.89A11.08,11.08,0,0,1,298.77,470H213.23a11.11,11.11,0,0,1-10.69-8.87l-6.72-47.82a16.07,16.07,0,0,0-9-12.22,155.3,155.3,0,0,1-21.46-12.57,16,16,0,0,0-15.11-1.71l-44.89,18.07a10.81,10.81,0,0,1-13.14-4.58l-42.77-74a10.8,10.8,0,0,1,2.45-13.75l38.21-30a16.05,16.05,0,0,0,6-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16,16,0,0,0-6.07-13.94l-38.19-30A10.81,10.81,0,0,1,49.48,186l42.77-74a10.81,10.81,0,0,1,13.14-4.59l44.9,18.08a16.11,16.11,0,0,0,15.17-1.75A164.48,164.48,0,0,1,187,111.2a15.94,15.94,0,0,0,8.82-12.14l6.73-47.89A11.08,11.08,0,0,1,213.23,42h85.54a11.11,11.11,0,0,1,10.69,8.87l6.72,47.82a16.07,16.07,0,0,0,9,12.22,155.3,155.3,0,0,1,21.46,12.57,16,16,0,0,0,15.11,1.71l44.89-18.07a10.81,10.81,0,0,1,13.14,4.58l42.77,74a10.8,10.8,0,0,1-2.45,13.75l-38.21,30a16.05,16.05,0,0,0-6.05,14.08C416.17,247.67,416.39,251.83,416.39,256Z" />
            </svg>
            {!products.loading &&
              products.loaded &&
              products.items?.length > 0 &&
              products.items.map((product, i) => {
                return (
                  <div className="menu-link" key={i}>
                    <Nav.Link
                      className=""
                      onClick={() => {
                        pushRoute(ROUTES.PRODUCT, {
                          id: product.id,
                        });
                        window.location.reload(false);
                        handleCloseMenu();
                      }}
                    >
                      <Row>
                        <Col xs={3}>
                          <Image
                            className="menu-link-image"
                            src={`${product['@id']}/@@images/image/thumb`}
                          />
                        </Col>
                        <Col xs={9}>
                          <h3>{product['product_name']}</h3>
                          <p>{product['title']}</p>
                        </Col>
                      </Row>
                    </Nav.Link>
                  </div>
                );
              })}
          </Menu>
        ) /* : isIdle === true ? (
        <Image
          role={'button'}
          className="logo-without-menu"
          src="/ulma-logo-white.svg"
          onClick={() => {
            pushRoute(ROUTES.PRODUCT, {
              id: products.items[0].id,
            });
          }}
        />
      )  */ /* : (
        <Image
          role={'button'}
          className="logo-without-menu"
          src="/ulma-logo.svg"
          onClick={() => {
            pushRoute(ROUTES.PRODUCT, {
              id: products.items[0].id,
            });
          }}
        />
      ) */
      }
    </>
  );
};

export default BurgerMenu;
