import { DEFAULT_LANGUAGE_DICT } from '../../config';
import {
  // SET_LANGUAGE,
  SET_INIT_LANGUAGE_ERROR,
  SET_INIT_LANGUAGE_PENDING,
  SET_INIT_LANGUAGE_SUCCESS,
  SET_LANGUAGE_ERROR,
  SET_LANGUAGE_PENDING,
  SET_LANGUAGE_SUCCESS,
} from '../actions/language';

const initialState = {
  language_dict: DEFAULT_LANGUAGE_DICT,
  loading: false,
  loaded: false,
  error: null,
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    // case SET_LANGUAGE:
    //   return {
    //     ...state,
    //     language_dict: action.payload.language_dict,
    //   };
    case SET_INIT_LANGUAGE_PENDING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case SET_INIT_LANGUAGE_SUCCESS:
      return {
        ...state,
        language_dict: action.payload.localstorage_language_dict,
        loading: false,
        loaded: true,
      };
    case SET_INIT_LANGUAGE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    case SET_LANGUAGE_PENDING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case SET_LANGUAGE_SUCCESS:
      return {
        ...state,
        language_dict: action.payload.localstorage_language_dict,
        loading: false,
        loaded: true,
      };
    case SET_LANGUAGE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default languageReducer;
