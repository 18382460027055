import { Carousel, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const ImagesCarousel = (props) => {
  const images = useSelector((state) => state.inactivityImages);

  return (
    <Carousel pause={false} fade controls={false} indicators={false}>
      {images?.items.map((item, i) => (
        <Carousel.Item key={i}>
          <Image className="carousel-image" src={item.image.download} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
