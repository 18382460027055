/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Image } from 'react-bootstrap';
import { IdleTimerProvider } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ImagesCarousel } from '../../components';
import { TIMEOUT } from '../../config';
import './VideoGallery.css';

import PhotoSwipe from 'photoswipe';
import 'photoswipe/dist/photoswipe.css';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const VideoGallery = ({ data }) => {
  const products = useSelector((state) => state.products);
  const [currentProduct, setCurrentProduct] = useState({});
  const [isIdle, setIsIdle] = useState();

  const location = useLocation();
  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#video-gallery',
      children: 'div',
      showHideAnimationType: 'fade',
      showAnimationDuration: 1000,
      hideAnimationDuration: 1000,
      pswpModule: () => PhotoSwipe,
    });

    lightbox.on('contentLoad', (e) => {
      const { content } = e;
      if (content.type === 'youtube-video') {
        e.preventDefault();
        content.element = document.createElement('div');
        content.element.className = 'pswp__youtube-video-container';
        const iframe = document.createElement('iframe');
        iframe.setAttribute('allowfullscreen', '');
        iframe.src = content.data.src;
        content.element.appendChild(iframe);
      }
    });

    lightbox.init();

    products.items.forEach((item) => {
      if (location.pathname.includes(item.id)) {
        setCurrentProduct(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, products]);

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = () => {
    setIsIdle(false);
    window.location.reload(false);
  };

  return (
    <div className="product-video-gallery-page">
      <IdleTimerProvider timeout={TIMEOUT} onIdle={onIdle} onActive={onActive}>
        {isIdle === true ? (
          <Fade direction={'right'} duration={'1500'}>
            <ImagesCarousel />
          </Fade>
        ) : (
          <>
            <Fade direction={'down'} duration={'2500'}>
              <div
                role={'button'}
                tabIndex={0}
                onClick={() => {
                  window.history.go(-1);
                }}
                onKeyDown={() => {
                  window.history.go(-1);
                }}
              >
                <h1>{currentProduct?.product_name}</h1>
              </div>
            </Fade>
            <Fade direction={'down'} duration={'2500'}>
              <div className="pswp-gallery" id="video-gallery">
                {currentProduct?.video_elements?.map((video_element, i) => (
                  <div key={i} className="video-gallery-element">
                    <a
                      data-pswp-type="youtube-video"
                      data-youtube-video-url={video_element?.video_url
                        ?.replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      href={video_element?.video_url
                        ?.replace('watch?v=', 'embed/')
                        .concat(`?vq=hd720&rel=0&loop=1`)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        width={'100%'}
                        className="video-gallery-image"
                        src={video_element?.image_url}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </Fade>
          </>
        )}
      </IdleTimerProvider>
    </div>
  );
};

export default VideoGallery;
