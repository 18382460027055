import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RotateModal } from '../../components';
import { setInitLanguage } from '../../store/actions/language';

const initialLoader = (App) => {
  return (props) => {
    const dispatch = useDispatch();
    const [currentOrientation, setCurrentOrientation] = useState(
      window.innerWidth <= window.innerHeight ? 'portrait' : 'landscape',
    );
    //   const language_dict = getLocalStorageLanguage();
    //   dispatch(setLanguage(language_dict || DEFAULT_LANGUAGE_DICT));
    // };

    useEffect(() => {
      dispatch(setInitLanguage());
      const handleResize = () => {
        if (window.innerWidth <= window.innerHeight) {
          setCurrentOrientation('portrait');
        } else {
          setCurrentOrientation('landscape');
        }
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <App {...props}>{props.children}</App>
        {!currentOrientation.startsWith('landscape') && <RotateModal />}
      </>
    );
  };
};

export default initialLoader;
