import React, { useState } from 'react';
import { Zoom } from 'react-awesome-reveal';
import { Col, Row } from 'react-bootstrap';
import { IdleTimerProvider } from 'react-idle-timer';

import { ImagesCarousel } from '../../components';
import { ProductTitle, TopIcons, ProductImage } from '../../components/index';
import { TIMEOUT } from '../../config';

const ProductPage = ({ verticalSwiper, data, advantage_element }) => {
  const [isIdle, setIsIdle] = useState();

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = (event) => {
    setIsIdle(false);
    window.location.reload(false);
  };

  return (
    <IdleTimerProvider timeout={TIMEOUT} onIdle={onIdle} onActive={onActive}>
      {isIdle === true ? (
        <Zoom duration={'1500'}>
          <ImagesCarousel />
        </Zoom>
      ) : (
        <div className="product-page">
          <Row>
            <Col xs={6} lg={5} className="p-lg-5 p-lg-3 product-info">
              <ProductTitle
                verticalSwiper={verticalSwiper}
                data={data}
                advantage_element={advantage_element}
              />
            </Col>
            <Col xs={6} lg={7} className="product-image">
              <TopIcons />
              <ProductImage data={data} />
            </Col>
          </Row>
        </div>
      )}
    </IdleTimerProvider>
  );
};

export default ProductPage;
